import styled from 'styled-components';
import logo from '../data/img/logo.png'
import { device } from "./device";


const LogoStyled = styled.div`

    position:fixed;
    top:3rem;
    left:3rem;
    
    img {
        cursor: ${props => props.hasNav ? "pointer" : "default"};
        width:15rem;
    }
`

export default ({nav}) => {

    const handleClick = nav ? nav : () => {};

    return (
        <LogoStyled hasNav={nav ? true : false}>
            <img src={logo} alt="Paretify Logo" onClick={handleClick}></img>

        </LogoStyled>
    )
}