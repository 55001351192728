import styled from "styled-components"
import {newColors} from "../data/colors"
import { BiError } from "react-icons/bi";
import { device } from "./device";
import useLocaleSuper from "../hooks/useLocaleSuper";

const Error = styled.div`
    position: fixed;
    top:50%;left:50%;
    transform:translate(-50%,-50%);
    width:40vw;
    height:30vh;
    padding:2rem;
    h1 {
        /* width:80%; */
        font-size:2rem;
    }
    @media ${device.mobileM} {
        width:80vw;
        height:40vh;
        /* h1 {font-size:2.5rem; width:80%;} */
    }
    background-color:${newColors[5]};
    border-radius:15px;
    z-index:100;

    a {
        text-decoration:none ;
        color:black;
        &:visited {
            color:black;
        }
        &:hover {
            text-decoration:underline ;

        } 
    }

    & > div {
        position:relative;
        height:100%;width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        .message {
            /* width:70%; */
            text-align:center ;
            h1 {
                font-weight:bold ;
            }
            p {
                margin-top:1em ;
                font-size:1.8rem ;
            }
        }
        #button {
            display:flex;
            justify-content:center;
            align-items:center;
            position:absolute;
            bottom:2rem;
            left:50%;
            transform:translate(-50%,0);
            width:auto;
            height:1rem;
            & > div {
                background-color: ${newColors[2]};
                color:white;
                padding:0.5rem 1rem;
                margin:0.5rem;
                cursor:pointer;
                &:hover {
                    background-color: ${newColors[4]};
                    /* color:black; */
                    
                }
            }
        }
    }
    #icon {
        position:absolute;
        top:0;
        left:0;
    }
    #support {
        margin-top:2rem;
        text-align:center;
        font-size:1.3rem ;
    }

`
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color:rgba(100,100,100,0.5);
    backdrop-filter: blur(5px);
    /* z-index: 600; */
    transition:all 0.25s ease-in-out;
    overflow:hidden;
    /* transition: backdrop-filter 3s linear, background-color 2s linear; */
    `

export function ErrorBox({message,prompt,cb,children, email = true}) {

    const lang = useLocaleSuper()

    const buttonPrompts = prompt.map((str,index)=> 
        <div key={index} onClick={cb[index]}><p>{str}</p></div> 
    )
    return (           
    <>
        <Overlay />
        <Error>
            <div>
                <div id="icon"><BiError size={30}/></div>
                <div className="message">
                    {lang(message)}
                    {children}
                    {email ? lang(<p id="support">If you can't resolve the issue please contact us at: <br /> <a href="mailto:support@paretify.com">support@paretify.com</a></p>,<p id="support">Hvis du ikke kan løse problemet kontakt os på: <br /> <a href="mailto:support@paretify.com">support@paretify.com</a></p>) : null}
                </div>
                <div id="button">
                    {buttonPrompts}
                </div>
            </div>
        </Error> 
    </> 
    );
}