import styled from "styled-components"

export const LoginForm = styled.form`
    display: flex;
    flex-direction:column;
    margin:0 auto;
    width:80%;
    /* align-items: center; */
    .strongness {
        color: ${props => props.isStrong ? "#8ed18e" : "red"};
        font-size:1.5rem;
        
    }
    .issame {
        color: ${props => props.isSame ? "#8ed18e" : "red"};
        font-size:1.5rem;

    }

    input {
        margin:0.5rem 0;
        /* justify-self:center; */
    }
    label {
        font-size: 1.5rem;
        margin: 1rem 0 0.5rem 0;
    }
    #error {
        font-size: 1.5rem;
        margin-top:1rem;
        text-align:center;
    }

`

export const InputBox = styled.input`
    box-sizing: border-box;
    border-radius:10px;
    height: 4rem;
    border:0;
    width:100%;
    /* margin:2rem; */
    display:inline;
    padding:1rem 0 1rem 1rem;
    transition: border-radius 0.2s ease-in-out;
    &:focus-visible {
        outline:none;
    }
    &[data-touched="true"] {
        border: ${props => props.valid ? "2px solid #8ed18e" :"2px solid red"};
    }
`