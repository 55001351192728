// import validator from "validator";
import { useState, useEffect, useContext } from "react";
import isStrongPassword from 'validator/lib/isStrongPassword'
import isEmail from 'validator/lib/isEmail'
// import { registerUser } from "../functions/getFunctions";
import LanguageSwitcher from "../components/LanguageSwitcher";
import TextBox from '../styled/TextBox'
import { SubmitButton } from "../styled/StyledSubmit";
import { LoginForm, InputBox } from "../styled/LoginForm";

import { useNavigate } from 'react-router-dom';
import { postNewUser, registerNewUSer } from "../functions/loginRequests";
import { StateContext } from "../data/stateContext";
import { ErrorBox } from "../styled/ErrorBox";
import Logo from "../styled/Logo";
import Loading from "../components/Loading";
// import { usePopUp } from "../hooks/usePopUp";
import CustomCheck from "../styled/CustomCheck";
import PasswordField from "../components/PasswordField";
import useLocaleSuper from "../hooks/useLocaleSuper";

const localCopy = {
    "en": {
        fname:"First Name",
        lname:"Last Name", 
        password:"Password",
        repeat:"Reapeat password",
        strongness:["Strong Password","Weak Password"],
        isSame:"Not identical"
        
    },
    "da-DK": {
        fname:"Fornavn",
        lname:"Efternavn",
        password:"Adgangskode",
        repeat:"Gentag adgangskode",
        strongness:["Stærkt kodeord", "Svagt kodeord"],
        isSame:"Ikke ens"
    }
}
export const passwordRules = { 
        
    minLength: 8, 
    minLowercase: 1,
    minUppercase: 1, 
    minNumbers: 1, 
    minSymbols: 0, 
    returnScore: false, 
    pointsPerUnique: 1, 
    pointsPerRepeat: 0.5, 
    pointsForContainingLower: 10, 
    pointsForContainingUpper: 10, 
    pointsForContainingNumber: 10, 
    pointsForContainingSymbol: 10 
}

const Register = () => {
    const [isDone, setisDone] = useState(false);
    const [hasErrors, sethasErrors] = useState();

    const {allCopy, locale} = useContext(StateContext)
    // const [copy, setcopy] = useState();

    const [registerData, setRegisterData] = useState({
        "username":"",
        "fname":"",
        "lname":"",
        "email":"",
        "password":"",
        "company":[],
        "team":[],
        "locale":"",
        "managingTeams":[]
    });

    const navigate = useNavigate()
    const lang = useLocaleSuper()

    useEffect(() => {
        if(allCopy !== "fetching") {
            // const copyParsed = {
            //     intro: parse(allCopy.register_intro)
            // }
            // setcopy(copyParsed)
        }
    }, [allCopy]);

 

    const [passwordCheck, setPasswordCheck] = useState("");
    const [strongness, setStrongness] = useState(null);
    const [isSame, setisSame] = useState(false);
    const [isEmailCheck, setIsEmailCheck] = useState(false)
    const [hasRead, setHasRead] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if(isSame && isEmailCheck) {
            
            // const {fname,lname} = registerData

            // const genereatedUserName = `${fname} ${lname}`

            const userData = {
                ...registerData,
                username:registerData.email, 
                firstName:registerData.fname,
                lastName:registerData.lname,
                confirmed:true
            }
            delete userData["fname"]
            delete userData["lname"]
            setIsLoading(true)
            // console.log(userData);

            registerNewUSer(userData).then(res => {
                console.log("🚀 ~ registerNewUSer ~ res:", res)
                // console.log(res);
                setIsLoading(false)
                if(res.email === userData.email) {
                    setisDone(true)
                    return
                }
                if(res.error) {
                    sethasErrors(<ErrorBox
                        message={[<h1>{res.error.message}</h1>,<h1>{res.error.message}</h1>]}
                        prompt={["Close"]}
                        cb={[()=>sethasErrors(null)]}
                    />)

                }

            })

            // postNewUser(userData).then(res => {
            // })

        } else {
            sethasErrors(<ErrorBox
                message={[<h1>The passwords don't match each other</h1>,<h1>Adgangskoderne er ikke ens</h1>]}
                prompt={["Close"]}
                cb={[()=>sethasErrors(null)]}
            />)
        }
    }
    const handleChange = (e) => {
        // console.dir(e.target.name);
        const inputData = {...registerData}
        if(e.target.name === "password") {
            // console.log(isStrongPassword(e.target.value, {...passwordRules, returnScore:false}));
            setStrongness(isStrongPassword(e.target.value, passwordRules))
        } else {
            e.target.dataset.touched = true;
        }
        if(e.target.name === "email") {
            setIsEmailCheck(isEmail(e.target.value))
            inputData[e.target.name] = e.target.value.toLowerCase()
            setRegisterData(inputData)
            return
        }

        inputData[e.target.name] = e.target.value
        setRegisterData(inputData)
    }
    const handleRepeat = (e) => {
        const repeatedPass = e.target.value
        // e.target.dataset.touched = true;
        setPasswordCheck(repeatedPass)
    }
    useEffect(() => {
        if(passwordCheck === registerData.password && registerData.password !== "") {
            setisSame(true)
        } else {
            setisSame(false)
        }
    }, [passwordCheck,registerData.password]);

    return (
        <>
        {isLoading ? <Loading /> : null}
        <Logo nav={()=> navigate("/")} />
        <TextBox style={{transform: "translate(0px, 10rem)", top:"5rem"}} className="scrollable">
            {isDone ? 
            <div id="success">
                {allCopy.register_success_noEmail}
                {/* {allCopy.register_success} */}
                <SubmitButton
                    style={{height:"5rem"}}
                    active={true}
                    type="button"
                    onClick={()=> navigate("/")}
                >
                    <span style={{fontSize:"2rem"}}>{locale === "en" ? "Go to Login" : "Gå til login" }</span>
                </SubmitButton>
            </div>
            
            :
            <>
            <div className="login">
                {/* <h1>REGISTER</h1> */}
                <div className="text">{allCopy.register_intro}</div>
            </div>
            <LoginForm onSubmit={handleOnSubmit} isStrong={strongness} isSame={isSame}>

                <label htmlFor="fname">{localCopy[locale].fname}</label>
                <InputBox 
                    name='fname' 
                    type="text" 
                    value={registerData.fname} 
                    onChange={handleChange}
                    placeholder={localCopy[locale].fname}
                    required={true}
                    data-touched={false}
                    valid={registerData.fname !== ""}
                    />

                <label htmlFor="lname">{localCopy[locale].lname}</label>
                <InputBox 
                    name='lname' 
                    type="text" 
                    value={registerData.lname} 
                    onChange={handleChange}
                    placeholder={localCopy[locale].lname}
                    required={true}
                    data-touched={false}
                    valid={registerData.lname !== ""}
                    />
                <label htmlFor="email">E-mail</label>
                <InputBox 
                    name='email' 
                    type="email" 
                    value={registerData.email} 
                    onChange={handleChange}
                    placeholder="Email"
                    required={true}
                    data-touched={false}
                    valid={registerData.email !== "" && isEmailCheck}
                />
                <label htmlFor="password">{localCopy[locale].password}</label>
                <PasswordField 
                    name='password' 
                    type="password" 
                    value={registerData.password} 
                    cb={handleChange} 
                    placeholder={localCopy[locale].password}
                    required={true}
                    data-touched={passwordCheck !== ""}
                    valid={isSame}
                    autocomplete={false}
                />
                <div className="strongness">
                    {strongness !== null ? strongness ? localCopy[locale].strongness[0] : localCopy[locale].strongness[1] : null}
                </div>
                    {/* MAKE STRONGNESS COMPONENT <Strongness val={strongness}/> */}
                <PasswordField 
                    name='passwordRepeat' 
                    type="password" 
                    value={passwordCheck} 
                    cb={handleRepeat} 
                    placeholder={localCopy[locale].repeat}
                    required={true}
                    data-touched={isSame}
                    valid={isSame}
                    autocomplete={false}
                />
                <div className="issame">
                    {strongness !== null ? isSame ? null : localCopy[locale].isSame : null}
                </div>

                <CustomCheck style={{margin:"2em 0"}}>
                    <input 
                        required={true} 
                        type="checkbox" 
                        name="policy"
                        checked={hasRead}
                        onChange={() => setHasRead(!hasRead)}
                    />
                    Jeg godkender PARETIFYs <a href="https://paretify.com/om/persondatapolitik-cookiepolitik/" target="_blank" rel="noopener noreferrer">persondatapolitik</a>
                    <span className="checkmark"></span>

                </CustomCheck>
                

                <SubmitButton 
                    style={{margin:"2rem 0"}}
                    type="submit"
                    // disabled={!isSame}
                    active={true}
                    // active={isEmailCheck && isSame && registerData.fname && registerData.lname !== ""}
                >
                    {lang("Register","Opret")}
                </SubmitButton>
            </LoginForm>
            </>

            }

        </TextBox>
        <LanguageSwitcher pos={['top:10rem;','left:3rem;']} color={3} />
        {hasErrors}
        </>
    )
}

export default Register