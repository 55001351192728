import { useContext } from "react";
import { StateContext } from "../data/stateContext";

function useLocaleSuper() {
    // const {locale} = useContext(StateContext)
    const {locale} = useContext(StateContext)

    const chooseLang = (en,dk,bs = dk) => {
        if(Array.isArray(en)) {
            return locale === "en" ? en[0] : en[1]
        }
        return locale === "en" ? en : locale === "bs" ? bs : dk;
    }
    return chooseLang;
}

export default useLocaleSuper;