import axios from "axios";

const copyAPI = axios.create({
    baseURL: `${process.env.REACT_APP_COPY_URL}`,
    headers:{
        'Content-Type':'application/json',
                    'Acess-Control-Allow-Origin':'*',
                    'Authorization':`Bearer ${process.env.REACT_APP_COPY_TOKEN}`,
                    'Accept': "application/json"
        }    
})

const BASE_URL = process.env.REACT_APP_COPY_URL
const BASE_COMPANY_URL = process.env.REACT_APP_COMPANY_URL

const GetWithAuth = (token) => {
    return {
        method:"GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
}

export const findLoginURL = async (email) => {

    const url = `getdomain/${email.toLowerCase()}`

    const response = await copyAPI.get(url).then(json => {
        // console.log(json);
        return json.data

    }).catch(err => console.error(err))

    return response
}

export const loginUser = async (credentials, path) => {


    // console.log("🚀 ~ loginUser ~ credentials:", credentials)
    const url = `${path}/api/auth/local`

    // console.log("🚀 ~ loginUser ~ url:", url)
    
    const fetchOptions = {
        method:"POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(credentials)
    }
    let fetchedData 
    await fetchFunction(url,fetchOptions).then(res => fetchedData = res)
    // console.log("🚀 ~ loginUser ~ fetchedData:", fetchedData)
    return fetchedData
}

// temporary call for transfering users.
        //find login type (for transfer of users from old servers) 
        //type 1: regular login, 
        //type 2: change password to the one provided from credentials,
        //type 3: send to register as new user
export const findLoginType = async (email,path) => {
    const url = `${BASE_COMPANY_URL }${path}/api/users-permissions/users/check/${email}`

    const type = await axios.get(url).then(res => {
        console.log(res);
        if(res.data.user === null) return 4
        if(res.data.user.confirmed) return 1
        if(!res.data.user.confirmed && res.data.user.firstName) return 2
        if(!res.data.user.confirmed && !res.data.user.firstName) return 3
    }).catch(err => console.error(err))
    return type
}
// export const loginUser_old = async (credentials) => {
//     const url = `auth/local`

//     const fetchOptions = {
//         method:"POST",
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body:JSON.stringify(credentials)
//     }
//     let fetchedData 
//     await fetchFunction(url,fetchOptions).then(res => fetchedData = res)
//     return fetchedData
// }

export const updateUserToNewSystem = async (credentials,path) => {

    const {identifier,password } = credentials
    let loginResponse

    await loginUser({identifier:identifier,password:process.env.REACT_APP_DEFAULT_PASSWORD},path).then(res => {
        // console.log("🚀 ~ awaitloginUser ~ res:", res)
        loginResponse = res
               
    })
    if(loginResponse.jwt) {
        const url = `${path}/api/auth/change-password`

        const dataload = {
            "currentPassword":process.env.REACT_APP_DEFAULT_PASSWORD,
            "password":password,
            "passwordConfirmation":password
        }

        const postOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginResponse.jwt}`
            },
            body:JSON.stringify(dataload)
        }
        await fetchFunction(url,postOptions).then(res => loginResponse = res)
    }
    return loginResponse
}

export const registerNewUSer = async (userData) => {
    
    const {email,password,firstName,lastName} = userData
    let path;
    let response;

    await findLoginURL(email).then(json => {
        path = json.path
    })
    if(!path) return {error:{message:"Din email er ikke på vores liste af inviterede brugere"}}
    // const cred = {identifier:email,password:process.env.REACT_APP_DEFAULT_PASSWORD}
    // console.log("🚀 ~ registerNewUSer ~ cred:", cred)

    await loginUser({identifier:email,password:process.env.REACT_APP_DEFAULT_PASSWORD},path).then(res => {
        console.log("🚀 ~ awaitloginUser ~ res:", res)
        if(res.error && res.error.message ===  "Invalid identifier or password") {
            response = {error: {message: "Din profil er ikke blevet oprettet endnu. Kontakt support!"}}
            return
        }
        response = res
               
    })
    if(response.error) return {error:{message:`Der gik noget galt! ${response.error.message}`}}
    if(response.jwt) {
        const url = `${path}/api/auth/change-password`

        const dataload = {
            "currentPassword":process.env.REACT_APP_DEFAULT_PASSWORD,
            "password":password,
            "passwordConfirmation":password
        }

        const postOptions = {
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${response.jwt}`
            },
            body:JSON.stringify(dataload)
        }
    
    
        await fetchFunction(url,postOptions).then(res => response = res)
        console.log("🚀 ~ registerNewUSer ~ response:", response)

        const updateData = {
            "firstName": firstName,
            "lastName": lastName,
            "confirmed":true
        }
        const updateOptions = {
            method:"PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${response.jwt}`
            },
            body:JSON.stringify(updateData)
        }
        await fetchFunction(`${path}/api/users/${response.user.id}`,updateOptions).then(res => {
            response = res
        })
        
    }
    return response


}

export const getPageCopy = async (identifer,locale) => {
    // const endpoint = `pages-copy/`
    // const endpoint = `pages-copy/?filter[identifier][$eq]=${identifer}`
    const endpoint = `getPageCopy/${identifer}/${locale}`

    let fetchedCopy;

    await tryFetch(endpoint,GetWithAuth(TOKEN)).then(res => {
        // console.log("🚀 ~ awaittryFetch ~ res:", res)
        fetchedCopy = res
    })
    return fetchedCopy
}

const TOKEN = process.env.REACT_APP_COPY_TOKEN

const defaultOptions = {
    
        method:"GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        }
}


const tryFetch = async (endpoint,options = defaultOptions) => {

    const url = `${BASE_URL}${endpoint}`
    let response;
    // const endpointNew = locale ? `${endpoint}&locale=${locale}`
    
    try {
        await fetch(url,options)
            .then(res => res.json())
            .then(json => response = json)
            .catch(err => {console.error(err);console.log(response);response = err.status})
        
    } catch (error) {
        console.error(error);
        response = error.status;
    }

    if(response.error) {
        console.error(response.error);
    }

    return response 
}

// export const postNewUser = async (userData) => {
//     const url = `${BASE_URL}auth/local/register`

//     console.log({...userData});

//     let fetchedData

//     const fetchOptions = {
//         method:"POST",
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body:{}
//     }

//     // THIS SHOULD ALL HAPPEN SERVER SIDE BY THE WAY!!!!
//     try {
//         let isInvitedUser = false;
//         let teamName; let managingTeams;
//         //Check if is invited by looking up the email in the backend
//         await fetchFunction(`companies/?populate=invited_users`) 
//             .then(json => {
//                 json.data.forEach(c => {
//                     c.attributes.invited_users.forEach(u => {
//                         if(u.email === userData.email) {
//                             isInvitedUser = true;
//                             // console.log(userData);
//                             userData.company[0] = c.id;
//                             userData.position = u.role
//                             managingTeams = u.managingTeams;
//                             // getUserDataFromCSV(userData.email,c.id)
//                             teamName = u.team
//                         }
//                     })
//                 })
//         })
        
//         if(isInvitedUser) {

//             await getTeamID(teamName,userData.company[0])
//                 .then(id => {
//                 console.log(id);
//                     if(id) {
//                         //Set team relation
//                         userData.team[0] = id; 
//                     } else {
//                         // No team relation.... should create error?
//                     }
//                 })
//                 .catch(err => console.error(err));
                
//              //If user is a team manager set that relation
//             if(managingTeams) {
//                 await getTeamID(managingTeams,userData.company[0])
//                 .then(id => {
//                         if(id) {
//                             userData.managingTeams[0] = id; 
//                         } 
//                     })
//                 .catch(err => console.error(err));
//             }
                
//             fetchOptions.body = JSON.stringify(userData)
//             //Register new user
//             await fetch(url,fetchOptions) 
//                 .then(res => res.json())
//                 .then(data => fetchedData = data)
//                 .catch(err => console.error(err))

//         } else {
//             fetchedData = {data:null, error:{message:"User is not on the list of invited users"}}
//         }
//     } catch (err) {
//         console.error(err);
//     }
//     return fetchedData
// }



export const getTeamID = async (teamName,cID) => {
    if(teamName === null) {
        return []
    }
    const url = `teams/?filters[name][$eq]=${teamName}&populate=company`
    let teamId

    await fetchFunction(url).then(json => {
        console.log(json, teamName);
        if(json.data.length > 0) {
            const thisTeam = json.data.filter(team => team.attributes.company.data.id === cID)
            console.log(thisTeam);
            teamId = thisTeam[0].id
        } else {
            teamId = false;
        }
        // teamId = json.data.length > 0 ? [json.data[0].id] : false
    })

    return teamId
}

export const getPaths = async () => {
    const endpoint = `copyserver/api/companies`

    let companies;

    await fetchFunction(endpoint,GetWithAuth(process.env.REACT_APP_COPY_TOKEN)).then(json => {
        companies = json.data.map(c => ({name:c.attributes.name,path:c.attributes.domain}))
    })
    return companies
}


export const getCopy = async (locale) => {
    const endpoint = `copyserver/api/login-copy/?locale=${locale}`
    let copy;

    await fetchFunction(endpoint).then(json => copy = json.data.attributes)

    return copy

}

export const requestReset = async (email) => {
    let response;
    let path
    let isUser;
    try {
        await findLoginURL(email).then(res => path = res.path)

        await checkIfUser(email, path)
            .then(res => {
                isUser = res
            })
        if(!isUser) {
            response =  {ok:false,message:`No users registered with ${email}`}
        } else {
            const url = `${BASE_COMPANY_URL}${path}/api/auth/forgot-password`
            
            const data = {"email": email}
        
            const fetchOptions = {
                method:"POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(data)
            }
            await fetch(url,fetchOptions)
                .then(res => res.json())
                .then(json => {
                    response = json
                })
            }
    } catch (error) {
        
    }
    return response

}

export const checkIfUser = async (email, path) => {
    // console.log("🚀 ~ checkIfUser ~ path:", path)
    const url = `${BASE_COMPANY_URL}${path}/api/users-permissions/users/check/${email}`
    let response 

    await fetch(url)
            .then(res => res.json())
            .then(json => response = json.user ? true : false)
            .catch(err => console.error(err))

    return response
}


export const updatePassword = async (data,path) => {

    const url = `${path}/api/auth/reset-password`

    const fetchOptions = {
        method:"POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
    }

    let token;

    await fetchFunction(url,fetchOptions).then(res => token = res)

    return token
}


const fetchFunction = async (endpoint,options = null) => {

    const url = `${BASE_COMPANY_URL}${endpoint}`
    let response;
    // console.log("🚀 ~ fetchFunction ~ url:", url)
    
    try {
        await fetch(url,options)
            .then(res => res.json())
            .then(json => response = json)
            .catch(err => console.error(err))
        
    } catch (error) {
        console.error(error);
    }

    return response 
}

