import React, {useEffect,useState} from 'react';
import { getCopy, getPageCopy } from '../functions/loginRequests';
import parse from 'html-react-parser';

export const StateContext = React.createContext()

function StateContextProvider(props) {

    // const [isFetching, setIsFetching] = useState(true)
    const [allCopy, setAllCopy] = useState("fecthing");
    // const [resultsCopy, setResultsCopy] = useState()
    const [locale, setLocale] = useState("da-DK");
    // const [user, setUser] = useState(null);
    // const [isLoggedIn, setIsLoggedIn] = useState(-1);
    const [jwt, setjwt] = useState();
    // const [isManager,setIsManager] = useState()
    // const [isAdmin,setIsAdmin] = useState()



    useEffect(() => {

        if(window.localStorage.m_token) {
            setjwt(window.localStorage.m_token)

        }
    }, []);

    useEffect(() => {
        if(locale) {
            fetchCopy()
        }
        return () => {}
    }, [locale]);

    // useEffect(() => {
    //     if(allCopy) {
    //     // console.log("🚀 ~ useEffect ~ allCopy:", allCopy)

    //         //NAVIGATE TO user.paretify.com (get user there)
    //         // window.location.href = `http://jsmdev.xyz:3000/${allCopy}`
    //         // getUser(setIsFetching,jwt)
    //         // .then(resp => {
    //         //     // console.log(resp);
    //         //     if(resp.error) {
    //         //         // console.log(resp.error);
    //         //         setIsLoggedIn(0)
    //         //     } else {
    //         //         setUser(resp)
    //         //         setIsManager(resp.position === "manager")
    //         //         setIsAdmin(resp.position === "admin")
    //         //         // console.log(isAdmin);
    //         //         if(resp.locale && resp.locale !== locale) {
    //         //             setLocale(resp.locale)
    //         //         }
    //         //         setIsLoggedIn(1)
    //         //     }
    //         // })
    //     }
    // }, [allCopy]);

    const saveJwt = (token) => {
        window.localStorage.m_token = token
        // document.cookie = `m_token=${token};domain=jsmdev.xyz`
        // setjwt(token)
        // if(!token) {
        //     setIsLoggedIn(0)
        // }
    }

    const fetchCopy = () => {
        // getCopy(locale)
        // .then(response => {
        //     console.log(response);
        //     const parsed = {...response}
        //     // console.log(response);
            
        //     Object.keys(response).forEach(key => {
        //         if(typeof response[key] === "string") {
        //             parsed[key] = parse(response[key])

        //         }
        //     })
        //     // setResultsCopy(response.results)

        //     setAllCopy(parsed)
            
        // })

        getPageCopy("login-copy",locale).then(response => {
            const parsed = {...response}
            // console.log(response);
            
            Object.keys(response).forEach(key => {
                if(typeof response[key] === "string") {
                    parsed[key] = parse(response[key])

                }
            })
            // setResultsCopy(response.results)

            setAllCopy(parsed)
        })


    }

    // const [reload, setreload] = useState(false);

    // const triggerReload = () => {

    // }

    // useEffect(() => {
    //     if(reload) {
    //         const tempUser = {...user}
            
    //         setTimeout(() => {
    //             setreload(false)
    //             setUser(tempUser)
    //         }, 200);
    //     }
    // }, [reload]);

    return (
        <StateContext.Provider 
            value={{
                allCopy:allCopy,
                // isFetching:isFetching,
                // isLoggedIn:isLoggedIn,
                // user:user,
                // setUser:setUser,
                saveJwt:saveJwt,
                jwt:jwt,
                // resultsCopy:resultsCopy,
                locale:locale,
                setLocale:setLocale,
                // isManager:isManager,
                // setIsManager:setIsManager,
                // isAdmin:isAdmin,
                // triggerReload:setreload,
                // reload:reload
            }
        }>
            {props.children}
        </StateContext.Provider>
    )
}

export default StateContextProvider