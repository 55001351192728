import styled from "styled-components"
import colors, {newColors} from "../data/colors"


export default styled.label`
    display: block;
    position: relative;
    padding-left: 3.5rem;
    margin-bottom: 1.2rem;
    cursor: ${props => props.disabled ? "default" : "pointer"};;
    font-size: 2.2rem;
    user-select: none;
    /* margin:2em 0; */
    /* padding */
    a {
        /* display:block;
        padding-bottom: 1em; */
        color:white;
        &:visited {
            color:white;
        }
        &:hover {
            color:${newColors[4]}
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 1px;
        width: 1px;
        left:12px;
        /* top:25px; */
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: ${props => props.disabled ? colors[1] : colors[4]};
    }
    &:hover input ~ .checkmark {
        background-color: ${props => props.disabled ? colors[1] : colors[3]};
    }
    input:checked ~ .checkmark {
        background-color: ${newColors[4]};
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);

    }

`