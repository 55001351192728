import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './routes/Login'
import StateContextProvider from './data/stateContext';
import Register from './routes/Register';
import ResetPassword from './routes/ResetPassword';
import ForgtPassword from './routes/forgot-password';
import GlobalStyle from './styled/GlobalStyles';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateContextProvider>
    <GlobalStyle />
      <Router>
        <Routes>
          {/* <Route path='/' exact element={<App />} /> */}
          <Route path='/' exact element={<Login />} />
          <Route path='/register/' element={<Register />} />
          <Route path='/forgot-password/' element={<ForgtPassword />} />
          <Route path='/reset-password/' element={<ResetPassword />} />
        </Routes>

      </Router>
    </StateContextProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
