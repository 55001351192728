import styled from "styled-components";
import colors, {newColors} from "../data/colors";

export const SubmitButton = styled.button`
/* position: fixed; */
/* bottom:10rem;
left:50%; */
padding: ${props => props.active ? "1rem": "0rem"};
background-color:${newColors[2]};
border:none;
border-radius: 5px;
margin:5rem auto;
overflow: hidden ;
height:${props => props.active ? "3em" : "0"};
color:white;
display: block ;
/* display:${props => props.active ? "block" : "none"} ; */
transition: padding 0.4s ease-in-out, height 0.4s ease-in-out;
cursor:pointer;
&:active {
    transform:scale(0.9) ;
}
&:hover {
    background-color:${newColors[4]} ;
}
`