import { useState, useContext, useEffect } from 'react'
import TextBox from '../styled/TextBox'
import { LoginForm, InputBox } from '../styled/LoginForm';
import { SubmitButton } from "../styled/StyledSubmit";

import { findLoginURL, findLoginType, loginUser, updateUserToNewSystem } from '../functions/loginRequests';

import { StateContext } from '../data/stateContext';
import { useNavigate } from "react-router-dom";
import { Logout as Register } from '../components/Logout';
import { useSearchParams } from 'react-router-dom';
import { ErrorBox } from '../styled/ErrorBox';
import Logo from '../styled/Logo';
import LanguageSwitcher from '../components/LanguageSwitcher';
import SelectPath from '../components/SelectPath';
import PasswordField from '../components/PasswordField';
import useLocaleSuper from '../hooks/useLocaleSuper';


const Login = () => {

    const {saveJwt, user, setUser, allCopy, locale} = useContext(StateContext)
        // const {saveJwt,setUser} = useContext(StateContext)
    const [errorMessage, seterrorMessage] = useState();
    const navigate = useNavigate();

    const lang = useLocaleSuper()
    const [credentials, setCredentials] = useState(
        {
            identifier:"",
            password:"",
        }
    );
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // console.log( );
        if(searchParams.get("logout")) {
            saveJwt(null)
            // setUser(null)
            navigate('/login')
        }
       
    }, []);

    const handleLogin = (credentials,path) => {

        

        loginUser(credentials,path).then(response => {

            if(response.jwt) {
                
                // saveJwt(res.jwt)
                // alert(`${process.env.REACT_APP_REDIRECT_URL}/id/${response.jwt}/${path}`)
                // window.location.href = `http://jsmdev.xyz:3000/`
                window.location.href = `${process.env.REACT_APP_REDIRECT_URL}/id/${response.jwt}/${path}`
                // window.location.href = `https://user.paretify.com/id/${res.jwt}`
                // navigate("/")
            }
            if(response.error) {
                // console.log(res.error);
                if(response.error.message === "Invalid identifier or password") {
                    seterrorMessage(<ErrorBox
                            message={[<><h1>Invalid email or password!</h1><p>If you forgot your password press "Forgot Password"</p></>,<><h1>Ugyldig email eller adgangskode!</h1><p>Hvis du har glemt din adgangskode klik på "Glemt kode"</p></>]}
                            prompt={[lang("Forgot Password","Glemt kode"),lang("Close","Luk")]}
                            cb={[() => navigate("/forgot-password/"),() =>seterrorMessage(null)]}
                        />)
                } else {
                    seterrorMessage(<ErrorBox
                            message={[<h1>{response.error.message}</h1>,<h1>{response.error.message}</h1>]}
                            prompt={[lang("Close","Luk")]}
                            cb={[()=>seterrorMessage(null)]}
                        />)
                }

            }
        })    
    }



    // useEffect(() => {
    //     // console.log(user);
    //     if(user) {
    //         navigate("/")
    //     }
    // }, [user]);

    const handleChange = (e) => {
        console.dir(e.target.name);
        const updatedCredentials = {...credentials}

        updatedCredentials[e.target.name] = e.target.value;
        // console.log(updatedCredentials);
        setCredentials(updatedCredentials)
    }
    
    const [selectPath, setselectPath] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(credentials.identifier === "ghost@paretify.com") {
            setselectPath(<SelectPath login={handleLogin} user={credentials} cb={() => setselectPath(null)} />)
            return
        }

        const path = await findLoginURL(credentials.identifier).then(res => {
            console.log("🚀 ~ path ~ res:", res)
            console.log("🚀 ~ findLoginURL ~ credentials:", credentials)
            if(!res.path) {
                seterrorMessage(<ErrorBox
                    message={[<><h1>No user found</h1><p>Please contact <a href="mailto:support@paretify.com">support@paretify.com</a></p></>,<><h1>Ingen  bruger fundet!</h1><p>Kontakt venligts support på <a href="mailto:support@paretify.com">support@paretify.com</a></p></>]}
                    prompt={[lang("Close","Luk")]}
                    email={false}
                    cb={[()=>seterrorMessage(null)]}
                />)
            }
            return res.path
        })

        //find login type (for transfer of users from old servers) 
        const loginType = await findLoginType(credentials.identifier,path)
        //type 1: regular login, 
        //type 2: change password to the one provided from credentials,
        //type 3: redirect to Register
        console.log("🚀 ~ handleSubmit ~ loginType:", loginType)

        if(loginType === 4) {
            seterrorMessage(<ErrorBox
                message={[<><h1>Your profile hasn't been created properly</h1><p>Please contact support at: <a href="mailto:support@paretify.com">support@paretify.com</a> or contact your adminstrator</p></>,<><h1>Din profil er ikke blever oprettet ordentligt på serveren</h1><p>Kontakt support på: <a href="mailto:support@paretify.com">support@paretify.com</a> eller kontakt din adminstrator</p></>]}
                prompt={["Close"]}
                email={false}
                cb={[()=>seterrorMessage(null)]}
            />)
        }
        if(loginType === 3) {
            seterrorMessage(<ErrorBox
                message={[<h1>You are not registeret yet please click on Register</h1>,<h1>Du er ikke oprettet som bruger endnu. Vær venlig at trykke på "Opret bruger"</h1>]}
                prompt={[lang("Register","Opret bruger"),"Close"]}
                cb={[() => navigate("register"),()=>seterrorMessage(null)]}
            />)
        }
        if(loginType === 2) {
            await updateUserToNewSystem(credentials,path).then(res => {
                // console.log("🚀 ~ awaitupdateUserToNewSystem ~ res:", res)
                if(res.jwt) {
                
                    // saveJwt(res.jwt)
                    // window.location.href = `http://jsmdev.xyz:3000/`
                    window.location.href = `${process.env.REACT_APP_REDIRECT_URL}/id/${res.jwt}/${path}`
                    // window.location.href = `https://user.paretify.com/id/${res.jwt}`
                    // navigate("/")
                }
                if(res.error) {
                    // console.log(res.error);
                    seterrorMessage(<ErrorBox
                            message={[<h1>{res.error.message}</h1>,<h1>{res.error.message}</h1>]}
                            prompt={["Close"]}
                            cb={[()=>seterrorMessage(null)]}
                        />)
    
                }
                
            })
        }

        if(loginType === 1) {
            handleLogin(credentials,path)
        }
    }

    const handleClick = (e) => {
        navigate("/register")
    }
    const handleForgot = (e) => {
        navigate('/forgot-password')

    }
    return (
        <>
        {selectPath}
        <Logo />
        <TextBox>
            <div className='login'>{allCopy ? allCopy.login_text : null}</div>
            <LoginForm id="login" name="login" onSubmit={handleSubmit}>
                <label htmlFor="login">Login</label>
                <InputBox  
                    onChange={handleChange} 
                    name="identifier" 
                    type="email" 
                    placeholder='Email' 
                    value={credentials.email}
                    autoComplete="email"
                    required={true}
                />
                <PasswordField 
                    cb={handleChange} 
                    value={credentials.password} 
                    placeholder={locale === "en" ? "Password" : "Adgangskode"}
                    
                />
                {/* <InputBox  onChange={handleChange} name="password" type="password" placeholder={locale === "en" ? "Password" : "Adgangskode"} value={credentials.password}/> */}
                <SubmitButton 
                    style={{margin:"2rem 0"}}
                    type="submit"
                    active={true}
                >
                    Login
                </SubmitButton>
            </LoginForm>
            <Register onClick={handleClick} >
                {locale === "en" ? "Sign up" : "Opret bruger"}
            </Register>
            <Register style={{right:"3rem"}} onClick={handleForgot} >
                {locale === "en" ? "Forgot Password?" : "Glemt adgangskode?"}
            </Register>
        </TextBox>
            <LanguageSwitcher  pos={['top:10rem;','left:3rem;']} color={3} />
        {errorMessage}
        </>
    )
}

export default Login