import styled, {keyframes} from "styled-components";
import { newColors } from "../data/colors";
// import logo from '../img/p_logo_white.svg'

const rotate = () => keyframes`
    0% {
        transform:rotate(0deg);
    }
    80% {
        transform:rotate(360deg);
        
    }
    100% {
        transform:rotate(360deg);

    }
`

const blink = () => keyframes`
    0% {
        opacity:1;
    }
    100% {
        opacity:0

    }
`

const scale = () => keyframes`
    0% {
        transform:scale(0.6);
    }
    100% {
        transform:scale(1.2);

    }
`

const StyledLoading = styled.div`
    position:fixed; 
    width:100vw;
    height:100vh;
    background-color:${newColors[5]};
    z-index:5000;
    /* transform: translate(-50%,-50%); */
    svg {
        animation: ${scale()} 5s linear, ${blink()} 0.6s linear infinite;
        position:fixed;
        top:calc(50% - 10rem);
        left:calc(50% - 10rem);
        width:20rem;
        fill:${newColors[3]}
    }
/* 
    img {
        width:100%;


    } */

`

function Loading() {
    return (
        <StyledLoading>
            {/* <img src={logo} alt="loading" /> */}
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 263.4">
                <path class="cls-1" d="M25.64,211.86V89.77H43.29l2.2,12.08A29,29,0,0,1,56.75,91.3,32.91,32.91,0,0,1,72.4,87.57,30.72,30.72,0,0,1,91,93.46a36.51,36.51,0,0,1,12.2,16,60.21,60.21,0,0,1,4.15,22.95q0,20-9.69,32.6t-26.24,12.6a33.11,33.11,0,0,1-14.79-3.26,32.18,32.18,0,0,1-11.1-8.83q.61,11.31.62,12.71v32.56ZM67.07,162a16.66,16.66,0,0,0,14.47-7.69q5.29-7.68,5.3-22,0-14.5-5.34-21.81t-14.83-7.29Q46.27,103.19,46,131q0,16,5.29,23.5T67.07,162Z"/>
                <path class="cls-1" d="M139.85,177.65q-12,0-19.42-6.63T113,151.91q0-13.41,8.78-19.89t26.52-8.2c1.62-.21,3.38-.42,5.26-.63s4-.41,6.36-.62,4.21-.4,5.57-.55v-4.55q0-7.84-3.61-11.42t-11-3.57q-10.51,0-26,5.88-.07-.23-2.59-7.14c-1.67-4.6-2.54-7-2.59-7.06a82.92,82.92,0,0,1,33-6.51q17.42,0,25.34,7.57T186,119.74V176H170.76q-.07-.31-1.72-5.26c-1.1-3.29-1.65-5.05-1.65-5.26a48.2,48.2,0,0,1-12.59,9.22A34.36,34.36,0,0,1,139.85,177.65Zm5.57-14.6a22.5,22.5,0,0,0,12.44-3.41,20,20,0,0,0,7.57-8.28V134.73c-.16,0-1.53.1-4.12.31s-4,.31-4.2.31q-12.48,1.11-18.28,4.71T133,151.68c0,3.66,1.08,6.47,3.22,8.43S141.44,163.05,145.42,163.05Z"/>
                <path class="cls-1" d="M201.36,176V89.77h17.26L221.29,103q8.46-13.58,24.55-13.57a39,39,0,0,1,5.5.31L251,106.87a44.33,44.33,0,0,0-6.51-.47q-11.37,0-17,6t-5.61,16.79V176Z"/>
                <path class="cls-1" d="M297.54,177.65q-20.79,0-32.36-12T253.6,132.61q0-20.55,10.91-32.72t29.74-12.24q17.81,0,27.65,11.14t9.85,29.58c0,.89,0,2.33,0,4.32s0,3.45,0,4.39H274.32q.31,12.09,6.63,18.71t17.38,6.63a54.29,54.29,0,0,0,27.14-7.37l2.91,14.59Q315.44,177.65,297.54,177.65Zm-22.83-53.51h37.66q0-10.51-4.78-16.17t-13.26-5.65a18.08,18.08,0,0,0-13.38,5.5Q275.49,113.31,274.71,124.14Z"/>
                <path class="cls-1" d="M380.2,177.17a44.27,44.27,0,0,1-14.63-2.07,18.86,18.86,0,0,1-9.15-6.56A27.86,27.86,0,0,1,351.76,158a69.16,69.16,0,0,1-1.38-15V102.24H336.26l.79-11.14,14.75-1.33,6.59-23.38,12.55-.08V89.77h21.42v12.47H370.94v41.12q0,9.81,2.83,14t10.43,4.16a63.48,63.48,0,0,0,9.57-.78l.55,14.75c-.1,0-.74.12-1.92.35s-2.3.45-3.37.63-2.45.35-4.12.51S381.66,177.17,380.2,177.17Z"/>
                <path class="cls-1" d="M406.52,77.22V59.64h20.63V77.22Zm0,98.78V89.77h20.55V176Z"/>
                <path class="cls-1" d="M448.57,176V103.42H434.92l1.33-12.08,12.32-1.57V85A53.66,53.66,0,0,1,450,71.57,24.27,24.27,0,0,1,454.77,62a19.42,19.42,0,0,1,8.82-5.88,41.91,41.91,0,0,1,13.62-1.92,88.34,88.34,0,0,1,16,1.49L491.41,71a80.33,80.33,0,0,0-10.12-.87q-6.6,0-9.38,3.18t-2.78,10.55v5.89h20.24v13.65H469.13V176Z"/>
                <path class="cls-1" d="M507.26,212.33a96.37,96.37,0,0,1-10.67-.63l-1-15.93a88.16,88.16,0,0,0,9.18.39q7.14,0,11.45-2.82t7.14-10.05q.31-.78,3.14-8.86L491.8,89.77h22l22.67,62.53Q539,143,547.7,117.62t9.61-27.85h22q-35.85,94.79-36.17,95.57-5.57,14.5-14,20.75T507.26,212.33Z"/>
                <path class="cls-1" d="M774.36,77.12a59.9,59.9,0,1,0-108.29,35.3l.65.88L729,175.6h39.86l0,0L729,135.66C757,125.35,774.36,103.77,774.36,77.12ZM688.29,95a31.67,31.67,0,1,1,57.85-17.83c0,17.82-15.53,31.36-40.12,35.57Z"/>
                <path class="cls-1" d="M699.28,153.35l-.65-.88L636.12,90H596.67l-.23.23,39.91,39.91C608.41,140.41,591,162,591,188.65a59.9,59.9,0,1,0,108.29-35.3Zm-48.4,67a31.72,31.72,0,0,1-31.66-31.68c0-17.84,15.52-31.38,40.11-35.57l17.73,17.72a31.36,31.36,0,0,1,5.5,17.85A31.72,31.72,0,0,1,650.88,220.33Z"/>
            </svg>           
            {/* <svg src={logo}></svg> */}
        </StyledLoading>
    );
}


export default Loading;