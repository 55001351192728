import { useEffect, useState } from "react";
import { ErrorBox } from "../styled/ErrorBox";
import { getPaths } from "../functions/loginRequests";

function SelectPath({login,user,cb}) {

    const [options, setoptions] = useState();
    const [path, setpath] = useState("loud");

    const callLogin = () => {
        console.log(user);
        login(user, path)
    }
    const handleSelect = e => {
        console.log(e);
    }

    useEffect(() => {
        getPaths().then(res => {
            setoptions(res.map(el => <option value={el.path} key={el.path}>{el.name}</option>));
        })
    }, []);
    return (  
        <ErrorBox message={[<h1>Select client</h1>,<h1>Select client</h1>]} prompt={["GO","CANCEL"]} cb={[callLogin,cb]}>
            <h2>SELECT PATH</h2>
            <select value={path} name="path" id="path" onChange={e => setpath(e.target.value)}>{options}</select>
        </ErrorBox>
    );
}

export default SelectPath;