import styled from "styled-components";
import { InputBox } from "../styled/LoginForm";
import { useState } from "react";
import { BiHide as Hide,BiShow as Show } from "react-icons/bi";

const Container = styled.div`
    position:relative;
    svg {
        position:absolute ;
        top:50%;
        right:0.5rem;
        translate: 0 -50%;
        cursor:pointer ;
        color:grey;
        &:hover {
            color:black;
        }
    }

`
function PasswordField({cb,value, name = "password", placeholder,required = true,touched = false,valid = true, autocomplete = "current-password"}) {
    const typeArr = ["password","text"]
    const [type, setType] = useState(false);
    
    return (
        <Container>
            <InputBox 
                onChange={cb} 
                name={name}
                type={typeArr[type ? 1 : 0]} 
                placeholder={placeholder}
                value={value} 
                data-touched={touched} 
                required={required} 
                valid={valid} 
                autoComplete={autocomplete}
            />
            {type ? 
                <Hide size={25} onClick={(e) => {e.preventDefault();setType(prev => !prev)}}/>
                :
                <Show size={25} onClick={(e) => {e.preventDefault();setType(prev => !prev)}}/>
            }

        </Container>
    );
}

export default PasswordField;